import DOMPurify from "dompurify";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer
} from "react";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  BrowserIsDevice,
  RAFButtonConstant,
  RAFTaskType
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import {
  TaskRow,
} from "../../../ActiveContacts/Task/TaskRow";
import { getPlannerRelatedRecords } from "../../../CareESIO/CareShiftLog/PlannerTask/PlannerLogHelper";
import "./../ServiceTransactionStyle.scss";

interface IProps {
  relatedToType?: string;
  careRecipientUID?: string;
  careRecipientName?: string;

  viewMode?: "Horizontal" | "Vertical";

  paddingClassname?: string;
}

interface IState {
  isLoading: boolean;
  taskItemsRow: TaskRow[];
  pinnedNotes: TaskRow[];
  showMoreClientNoteContent: boolean;
}

function ServiceTransactionNotes({
  careRecipientUID,
  careRecipientName,
  paddingClassname = "p-2 p-md-3",
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      taskItemsRow: null,
      pinnedNotes: null,
      showMoreClientNoteContent: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [careRecipientUID]);

  useEffect(() => {
    const eventKey = `${RAFEventName.ReloadPinnedTaskContent}_${RAFEntityName.Task
      }_${"PinnedNotesOnly"}_${careRecipientUID}`;
    subscribeRAFEvent(eventKey, refreshTaskList);

    return () => {
      unsubscribeRAFEvent(eventKey, refreshTaskList);
    };
  });

  const refreshTaskList = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      let argscareRecipientUID = args.detail.careRecipientUID;
      if (argscareRecipientUID === careRecipientUID) {
        refresh();
      }
    }
  };

  const refresh = async () => {
    setState({
      isLoading: true,
    });

    if (isNotNullAndUndefined(careRecipientUID)) {
      const sortQuery: string[] = [
        propertyOf<TaskRow>("Pinned"),
        propertyOf<TaskRow>("SortOrder"),
        `${propertyOf<TaskRow>("TaskDate")} desc`,
      ];

      const taskItemsRow = await getPlannerRelatedRecords(
        careRecipientUID,
        null,
        RAFTaskType.Note,
        sortQuery
      );

      const pinnedNotes = isNotEmptyArray(taskItemsRow)
        ? taskItemsRow.filter((x) => x.Pinned)
        : [];

      setState({
        isLoading: false,
        taskItemsRow,
        pinnedNotes,
      });
    } else {
      setState({
        isLoading: false,
        taskItemsRow: [],
        pinnedNotes: [],
      });
    }
  };

  //show more ClientNote start
  const showMoreClientNoteContent = () => {
    if (state.showMoreClientNoteContent === true) {
      setState({ showMoreClientNoteContent: false });
    }
    else {
      setState({ showMoreClientNoteContent: true });
    }
  };

  //show more ClientNote end

  const headerTemplate = (item: TaskRow) => {
    return (
      <div
        className={`position-relative pointer`}
      >
        <div className={`align-items-center row g-3 flex-nowrap`}>
          <div className="col">
            <div className="d-flex align-items-center justify-content-between">
              <span className="subtitle_1 ecllipseFirstLine">
                {item.Title}
              </span>

              {item.Pinned && (
                <span className="note_pin_outer_div">
                  <i className="note_pin_icon fas fa-thumbtack text_orange"></i>
                  {/* <i className="note_pin_icon fas fa-thumbtack" style={{ color: item.ColourCode }}></i> */}
                </span>
              )}
            </div>
            <div className="d-flex align-items-baseline body_3">
              <span className="body_3 pe-1">
                {item.Pinned ? "Pinned" : "Added"}
              </span>
              <span>
                {isNotNullAndUndefined(item.CreatedBy) ? (
                  <span className="d-flex align-items-center">
                    <span className="body_3 pe-1">by</span>
                    <span className="body_3 text-decoration-underline pe-1 ecllipseFirstLine word-break-all">
                      {item.CreatedBy}
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </span>
              <div onClick={(e) => e.stopPropagation()}>
                <RAFRecordInfo
                  createdBy={item.CreatedBy}
                  createdDate={item.CreatedDate}
                  modifiedDate={item.ModifiedDate}
                  modifiedBy={item.ModifiedBy}
                  lastActivityDate={item.LastActivityDate}
                  content=""
                  hideClockIcon
                  dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                  spanContentClassName="body_3"
                ></RAFRecordInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const notesContent = (item: TaskRow) => {
    const colorCodeName = isNotNullAndUndefined(item.ColourCode)
      ? item.ColourCode
      : "#67C6C2";
    return (
      <div
        key={item.UID}
        className={
          props.viewMode === "Horizontal" ? "col-auto" : "col-12"
        }
        {...(BrowserIsDevice && props.viewMode === "Horizontal"
          ? { style: { width: "80%", minWidth: "300px" } }
          : {})}
      >
        <CustomCardWidget
          cardContentClassName={paddingClassname}
          style={{
            backgroundColor: colorCodeName,
            borderColor: colorCodeName,
          }}
        >
          {headerTemplate(item)}
          {IsNotNullOrWhiteSpace(item.Description) && (
            <div
              id={"summaryTask_" + item.UID}
              // style={{ backgroundColor: item.ColorCode }}
              className="mt-2"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    item !== null
                      ? DOMPurify.sanitize(item.Description)
                      : undefined,
                }}
                className="remove_paragraph_margin body_2_dark description-text"
              ></span>
            </div>
          )}
        </CustomCardWidget>
      </div>
    );
  };

  const getNotesContentDiv = () => {
    const { pinnedNotes } = state;
    if (isNotEmptyArray(pinnedNotes)) {
      return (
        pinnedNotes.map((item) => {
          return (
            notesContent(item)
          );
        })
      );
    } else {
      return (
        <div className={"col-12"}>
          <RAFEmptyState title={"You do not have any pinned notes."} />
        </div>
      );
    }
  };

  const getBodyContent = () => {
    if (state.isLoading === false) {
      const { pinnedNotes } = state;
      if (isNotEmptyArray(pinnedNotes)) {
        return (
          <div className="p-md-1">
            <RAFPermissionRender
              permissionName={CareRecipientPermissionConstants.CareRecipientReadNotes}
            >
              <div className="service_transaction_note shadow-sm">
                <div className={`service_transaction_note_content${state.showMoreClientNoteContent ? ' active' : ''}`}>
                  <div
                    className={`row align-items-center gy-3 g-0${props.viewMode === "Horizontal"
                      ? " gx-3 flex-nowrap"
                      : " flex-column"
                      }`}
                  >
                    {getNotesContentDiv()}
                  </div>
                </div>
                <div className={`service_transaction_note_footer${state.showMoreClientNoteContent ? ' active' : ''}`}>
                  <div className="">
                    <RAFButtonComponent className="custom-button-sm btn_brand_primary white"
                      action={state.showMoreClientNoteContent ? RAFButtonConstant.HideLess : RAFButtonConstant.ShowMore}
                      onClick={showMoreClientNoteContent}
                      iconPosition="right"
                      btnContent={`${pinnedNotes.length} pinned notes`}
                    ></RAFButtonComponent>
                  </div>
                </div>
              </div>
            </RAFPermissionRender>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Loading..." />
        </div>
      );
    }
  };

  return (
    getBodyContent()
  );
}

export default React.memo(ServiceTransactionNotes);
